import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ToastrService } from "ngx-toastr";
import { NotificationModel } from '../models/notification.model';
import { HttpClient } from "@angular/common/http";
import { NotificationRequestModel } from '../models/notification-request';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    
    notifications = new BehaviorSubject(null);
    notificationSubscription: Subscription;
    notificationCleared = new BehaviorSubject(false);

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private toastr: ToastrService,
        private http: HttpClient
    ) {
        this.angularFireMessaging.messages.subscribe(messages => {
        })
    }
    
    requestPermission() {
        if(Notification.permission !== 'denied') {
                this.angularFireMessaging.requestToken.subscribe(
                    (token) => {
                        localStorage.setItem('fireMessageToken', token);
                    },
                    (err) => {
                    }
                );
        } else {
            // this.toastr.warning('It seems you denied notification from cybase, Allow permission to get notify', 'Notification denied', {
            //     timeOut: 10000
            // });
        }
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload: NotificationModel) => {
                let notification: NotificationModel = {
                    notification: payload.notification,
                    data: payload.data,
                    fcmMessageId: payload.fcmMessageId,
                    createdDt: new Date()
                };
                this.notifications.next(payload);
                this.toastr.info(payload?.notification.body, payload.notification.title);
        }, (error) => {

        });
    }

    getNotificationList(requestData: NotificationRequestModel): Observable<any> {
      return this.http.post(environment.REST_API_SERVER_URL + 'Notification/GetNotifications', requestData);
    }

    clearNotification(requestData: NotificationRequestModel): Observable<any> {
        return this.http.post(environment.REST_API_SERVER_URL + 'Notification/ClearNotification', requestData);
    }
}