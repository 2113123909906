export const DynamicHeaderMenuConfig = {
  items: [
    {
      title: 'Dashboards',
      root: true,
      alignment: 'left',
      page: '/dashboard',
      translate: 'MENU.DASHBOARD',
    },
    {
      title: 'Setup',
      root: true,
      alignment: 'left',
      page: '/builder',
    },
    {
      title: 'Teams',
      bullet: 'dot',
      page: '/material',
      icon: 'flaticon-interface-7',
     
    },
    {
      title: 'Projects',
      bullet: 'dot',
      page: '/material',
      icon: 'flaticon-interface-7',
     
    },
    {
      title: 'Clients',
      bullet: 'dot',
      page: '/material',
      icon: 'flaticon-interface-7',
     
    }
  ]
};
