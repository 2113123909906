
export const environment = {
  production: false,
  appVersion: 'v726demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  authTokenKey: 'access_token',
  refreshTokenKey: 'refresh_token',
  REST_API_SERVER_URL:  '/api/',
  apiUrl:  '/api/',
  url_old: '/api/',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCRF9Q1ttrleh04hqRlP_CqsFCPU815jJk',
  FIREBASE_CLOUD_MESSAGING_SERVER_KEY: 'AAAAsXE3W_E:APA91bElVaFzFmbgf30fgcmqXDfNrPBUWrQmclJeI2oXgbJ7t45MY5kWF_uHiW3wnODQii5dSppBxW2BjvUasN1tI4n97UuvEqJxa8rRh0DHz7oOVHnXkjNWLJ1lUFRoAGtsHz4jc-ji',

  firebase: {
    // ************* Firebase UAE ************
    apiKey: "AIzaSyCRF9Q1ttrleh04hqRlP_CqsFCPU815jJk",
    authDomain: "cyberspace-fa682.firebaseapp.com",
    // databaseURL: "https://48cb1ccac9354e968152e18166ff79c3.asia-southeast1.firebasedatabase.app",
    databaseURL: "https://cyberspace-fa682-default-rtdb.asia-southeast1.firebasedatabase.app/",
    projectId: "cyberspace-fa682",
    storageBucket: "cyberspace-fa682.appspot.com",
    messagingSenderId: "762108664817",
    appId: "1:762108664817:web:d2d052151ec6cc37104250",
    measurementId: "G-VRR0PD6FZ6"


    // ************* Firebase Qatar ************
    // apiKey: "AIzaSyBo6NT8XAyG6ajisXFdC5WlvOlZ2PkhfJ0",
    // authDomain: "cyberspaceqatar.firebaseapp.com",
    // databaseURL: "https://cyberspaceqatar-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "cyberspaceqatar",
    // storageBucket: "cyberspaceqatar.appspot.com",
    // messagingSenderId: "725809564439",
    // appId: "1:725809564439:web:e5cb925217cba18a698552",
    // measurementId: "G-1BH30MRB9N"


    // ************* Firebase Canada ************
    // apiKey: "AIzaSyBmon8Zz_ndYytr3zNhQjjZcVms8pCtge8",
    // authDomain: "cybasecanada-e223e.firebaseapp.com",
    // databaseURL: "https://cybasecanada-e223e-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "cybasecanada-e223e",
    // storageBucket: "cybasecanada-e223e.appspot.com",
    // messagingSenderId: "512514325596",
    // appId: "1:512514325596:web:a95ba09f3703bc27a6fa99",
    // measurementId: "G-M2JP9B6M4V"


    // ************* Firebase Bahrain ************
    // apiKey: "AIzaSyD9XbpkZNl6H62gCfgJUQs7dfQiyYY9dfA",
    // authDomain: "cybasebahrain-a6c64.firebaseapp.com",
    // databaseURL: "https://f1ddd1d85d9e422395df9f935f221b4d.asia-southeast1.firebasedatabase.app/",
    // projectId: "cybasebahrain-a6c64",
    // storageBucket: "cybasebahrain-a6c64.appspot.com",
    // messagingSenderId: "840509867067",
    // appId: "1:840509867067:web:f5722506cb22d7ccbc881e"


    // ************* Firebase US ************
    // apiKey: "AIzaSyB9T_nT-VxGf5i3wYcQJjnOdUY6_zDYchE",
    // authDomain: "cybaseusae.firebaseapp.com",
    // databaseURL: "https://bc27300475434fab96291029315b0ff6.asia-southeast1.firebasedatabase.app/",
    // projectId: "cybaseusae",
    // storageBucket: "cybaseusae.appspot.com",
    // messagingSenderId: "941018484732",
    // appId: "1:941018484732:web:5eb1e92323a054f115ec79"
  }
};
