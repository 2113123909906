import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  HostListener
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as arLang } from './modules/i18n/vocabs/ar';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from './shared/services/messaging.service';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private toastrService: ToastrService,
    private messagingService: MessagingService
  ) {

    this.translationService.loadTranslations(
      enLang,
      arLang
    );
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();

    if(!window.navigator.onLine) {
      this.toastrService.warning("Check your internet connection","No Internet Connection!", 
        {disableTimeOut: true, tapToDismiss: false});
    }

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        //  this.splashScreenService.init();
        this.splashScreenService.hide();
        
        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  @HostListener('window:offline', ['$event'])
  OfflineEvent(event: Event) {
    this.toastrService.clear();
    this.toastrService.warning("Check your internet connection","No Internet Connection!", {disableTimeOut: true, tapToDismiss: false});
  }

  @HostListener('window:online', ['$event'])
  OnlineEvent(event: Event) {
    this.toastrService.clear();
    window.location.reload();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
