
export const locale = {
    currentTheme: "rtl",
    lang: "ar",
    data: {
        TRANSLATOR: {
            SELECT: "Wähle deine Sprache",
        },
        MENU: {
            Dashboard: "لوحة القيادة",
            Setup: "الإعدادات",
            Teams: "فريق العمل",
            Projects: "المشاريع",
            Clients: "العملاء",
            Chat: 'المحادثات',
            My_page: 'صفحتي',
            HOME: 'الرئيسة',
            Message_History: 'Chat History',
            history: 'History'
        },
        DASHBOARD: {
            PENDING_TASKS: "المهام العالقة",
            UPCOMING_MEETINGS: "الإجتماعات القادمة",
            ACTIVE_PROJECTS: "مشاريع نشطة",
            ACTIVE_FOLLOWUPS: "المتابعات النشطة",
            TODAY_MEETINGS: "إجتماعات اليوم",
            TODAYS_ACTIVITY: "أنشطة اليوم",
            NO_ACTIVITIES_TODAY: "لا يوجد أنشطة اليوم",
            TASKS: "المهام",
            ASSIGNED: "معين",
            CREATED: "تم إنشاؤها",
            ADD_TASK: "إضافة مهمة",
            ADD_MEETING: "إضافة إجتماع",
            FILES: "الملفات",
            FOLLOW_UPS: "المتابعات",
            TIMESHEET: "جدول العمل اليومي",
            CREATE_TIMESHEET: "إنشاء جدول عمل يومي",
            ADD_FOLLOWUP: "أضف متابعة",
            CREATE: "إنشاء",
            PROJECT_TASKS: 'مهمات المشروع',
            MEETINGS: 'الإجتماعات',
            ADD_REMINDER: 'إضافة تذكير',
            EMPLOYEE_FILES: 'ملفاتي',
            PROJECT_FILES: 'ملفات المشاريع',
            CLIENT_FILES: 'ملفات العملاء'
        },
        TEAMS: {
            STATUS: {
                ACTIVE: 'نشط',
                INACTIVE: 'غير نشط'
            },
            MY_TEAM: 'فريقي',
            TEAM_MEMBER: 'عضو في فريق',
            UNASSIGNED_EMPLOYEE_LIST: 'قائمة الموظفين لم يسند لهم أية مهمة',
            EMPLOYEE_VACANT: 'Employee Vacant',
            ADD_NEW: 'Add New',
            MARK_AS_ADMIN: 'Mark this employee as Admin'
        },
        PROJECTS: {
            STATUS: {
                IN_PROGRESS: 'قيد الإنجاز',
                COMPLETED: 'منجز'
            },
            ARCHIVE: 'أرشيف',
            UNARCHIVE: 'إسترجاع من الأرشيف',
            FILTER_BY_STATUS: 'فرز بحسب الحالة',
            ACTIVATE: 'تفعيل',
            CHANGE_STATUS: 'تغيير الحالة',
            PROJECT_TASKS: 'مهمات المشروع',
            GANTT_CHART: 'الرسم التوضيحي',
            PROJECT_DETAILS: 'تفاصيل المشروع',
            PROJECT_ID: 'رقم المشروع',
            MILESTONES: 'النقاط العلام',
            ADD_MILESTONE: 'إضافة نقاط العلام',
            PROJECT_MILESTONES: 'النقاط العلام للمشروع',
            FINANCE: 'Finance',
            MILESTONE_ADDED_SUCCESSFULLY: 'تمت إضافة النقاط العلام بنجاح',
            DRAG_TASKS: 'Drag and drop the task here to bind with milestone',
            MILESTONE_ALERT1: 'تاريخ البداية والنهاية للنقاط العلام يجب أن تكون ضمن حدود تاريخ البداية والنهاية للمشروع',
            MILESTONE_ALERT2: "ولا يجب أن تتقاطع مع تواريخ النقاط العلام الأخرى.",
            PROJECT_GOALS: "Project Goals",
            ADD_PROJECT_GOAL: "Add project goal",
            UPDATE_PROJECT_GOAL: "Update project goal",
            PROJECT_GOAL: "Project Goal",
            PROJECT_GOAL_DELETED: 'Successfully deleted project goal',
            CONFIRM_DELETE_PROJECT_GOAL: 'Are you sure you want to delete this project goal',
            ADD_TASK_TYPE: 'Add task type(max 32 characters)',
            DEPARTMENT_CHANGE_CONFIRM: 'هل أنت متأكد أنك تريد تغيير القسم? سيتم حذف جميع مدراء المشاريع',
            CANNOT_UPDATE_OWNER: "المشروع قيد التنفيذ ،لا يمكنك تغيير المدراء",
            CANNOT_UPDATE_DEPARTMENT: "هذا المشروع قيد التنفيذ،لا يمكنك تغيير الفسم",
            CANNOT_UPDATE_PROJECT_NAME: "هذا المشروع قيد التنفيذ لا يمكنك تغيير الإسم",
            CANNOT_UPDATE_PROJECT_ID: "هذا المشروع قيد التنفيذ لا يمكنك تغيير رقم المشروع",
            CANNOT_CREATE_MILESTONE1: "This project is not live, You can't create milestone",
            CANNOT_CREATE_MILESTONE2: "This project is not active, You can't create milestone",
            NOT_LIVE: "Not Live",
            CHOOSE_VENDOR: 'Choose a project vendor',
            ADD_TASK_TYPE_HINT: 'Type the task type and click + to add',
            ADD_MODULE_HINT: 'Type module name and click + to add'
        },
        TASKS: {
            STATUS: {
                REQUESTED: 'تم تقديم الطلب',
                IN_PROGRESS: 'قيد الإنجاز',
                COMPLETED: 'منجز',
                NOT_STARTED: 'لم تبدأ',
                WORKING_ON: 'قيد الإنجاز',
                SUBMITTED_FOR_REVIEW: 'تم إرسال الطلب'
            },
            ACTION_STATUS: {
                MARK_AS_COMPLETED: "وضع علامة منجز",
                WAITING_FOR_COMPLETION: 'في انتظار الإنجاز',
                COMPLETED: 'تم إنجاز العمل',
                WAITING_FOR_APPROVAL: 'في انتظار الموافقة',
                START: 'إبدأ'
            },
            PRIORITY: {
                LOW: 'منخفض',
                MEDIUM: 'متوسط',
                HIGH: 'عالي',
                URGENT: 'مستعجل',
                VERY_URGENT: 'مستعجل جداً'
            },
            TASK: 'مهمة',
            TASK_DETAILS: 'تفاصيل المهمة',
            ADD_SUB_TASK: 'إضافة مهمة فرعية',
            UPDATE_SUB_TASK: 'تحديث المهمة الفرعية',
            UPDATE_TASK: 'تحديث المهمة',
            SUB_TASK_TITLE: 'عنوان المهمة الفرعية',
            ASSIGN_TO_ME: 'معينة لي',
            TASKS_ASSIGNED: 'المهمات المعينة لي',
            TASKS_CREATED: 'المهمات المنشأة',
            VIEW_TASK: 'عرض المهمة',
            NEW_UPDATES: 'تحديث جديد',
            UPDATES: 'تحديث',
            ACTIVITIES: 'النشاطات',
            CONFIDENTIAL_TASK: 'Confidential Task',
            SUCCESS_MESSAGE: {
                TASK_CREATED: 'تم إنشاء المهمة بنجاح',
                TASK_UPDATED: 'تم تحديث المهمة بنجاح'
            }
        },
        TASK_STATUS_MESSAGE: {
            NOT_STARTED: 'المهمة لم تبدأ',
            INPROGRESS: 'المهمة قيد الإنجاز',
            SUBMITTED: 'تم ارسال المهمة ',
            COMPLETED: 'تم انجاز المهمة '
        },
        TASK_ACTIVITY: {
            ADD_ACTIVITY: "إضافة مراحل",
            ADD: 'إضافة',
            ADDED_SUCCESSFULLY: 'تمت إضافة المراحل بنجاح',
            NO_ACTIVITY: 'الرجاء إضافة مرحلة',
            TASK_ACTIVITY: 'مراحل المهمة',
            DELETED_SUCCESSFULLY: 'تم حذف المرحلة بنجاح',
            CONFIRM_DELETE: 'هل أنت متأكد أنك تريد حذف هذه المرحلة',
            CONFIRM_COMPLETE: 'هل أنت متأكد أنك تريد وضع هذه المرحلة على أنها منجزة',
            COMPLETED_SUCCESSFULLY: 'تم بنجاح إتمام المرحلة'
        },
        MEETINGS: {
            MEETING: 'إجتماع',
            TODAY: 'اليوم',
            PREVIOUS: 'السابق',
            UP_COMING: 'القادمة',
            LATER: 'لاحقا',
            ONLINE_MEETING: 'إجتماع دون اتصال بالإنترنت',
            OFFLINE_MEETING: 'إجتماع دون اتصال بالإنترنت',
            MEMBER: 'العضو',
            NO_MEETING: 'لا يوجد إجتماعات اليوم',
            MEETING_DATE: 'تاريخ الإجتماع',
            MEETING_TIME: 'توقيت الإجتماع',
            COPY_MEETING_LINK: 'نسخ رابط الاجتماع',
            SHARE_MEETING_LINK: 'مشاركة رابط الاجتماع',
            VIEW_RECORDING: 'عرض التسجيل',
            DOWNLOAD_RECORDING: 'تحميل التسجيل',
            MINUTES_OF_MEETING: 'محضر الإجتماع',
            ADD_MINUTES_OF_MEETING: 'إضافة محضر الإجتماع',
            ONLINE: 'متصل',
            OFFLINE: 'غير متصل',
            DURATION_HOUR: 'المدة بالساعات',
            DURATION_MINUTE: 'المدة بالدقائق',
            START_MEETING: 'إبدأ الإجتماع',
            JOIN_MEETING: 'الانضمام إلى الإجتماع',
            MEETING_AGENDA: 'جدول أعمال'
        },
        EVENTS: {
            EVENT: 'حدث',
            ADD_EVENT: 'إضافة حدث',
            EVENTS: 'الأحداث'
        },
        FILES: {
            EMPLOYEE_FILES: 'ملفات الموظفين',
            PROJECT_FILES: 'ملفات المشاريع',
            CLIENT_FILES: 'ملفات العملاء',
            CREATE_FOLDER: 'إنشاء حافظة',
            CREATE_FILES: 'إنشاء ملف',
            UPDATE_FOLDER: 'تحديث الحافظة',
            LAST_MODIFIED: 'آخر تعديل',
            UPDATE_FILE: 'Update File',
            FILE_UPDATED: 'Successfully updated file'
        },
        CLIENT: {
            ADD_CLIENT: 'إضافة عميل',
            ADD_VENDOR: 'إضافة مزود جديد',
            UPDATE_VENDOR: 'تحديث معلومات المزود',
            ORGANISATION_NAME: 'اسم المؤسسة',
            TOTAL_PIPELINE: 'مجموع المبيعات',
            UPDATE_CLIENT: 'تحديث العميل',
            ADD_LOGO: 'إضافة شعار',
            UPDATE_LOGO: 'تحديث الشعار',
            SALE_AMOUNT: 'مبلغ المبيعات',
            SALES_PIPELINE: 'المبيعات',
            CLIENT_CONTACTS: 'جهات إتصال العميل',
            ADD_CLIENT_CONTACT: 'إضافة إلى جهات إتصال العميل',
            UPDATE_CLIENT_CONTACT: 'ـحديث جهات إتصال العميل',
            ADD_CONTACT: 'إضافة جهة اتصال',
            UPDATE_CONTACT: 'حدث جهة اتصال',
            ADD_SALES_PIPELINE: 'اضافة مبيعات',
            CREATE_SALES_PIPELINE: 'إنشاء مبيعات',
            UPDATE_SALES_PIPELINE: 'تحديث المبيعات',
            VENDOR: 'مزود',
            VENDORS: 'Vendors',
            CLIENT: 'عميل',
            MY_PRIVATE_CLIENT: 'العملاء الخاصيين بي',
            MY_PRIVATE_VENDOR: 'المزودين الخاصيين بي',
            BUSINESS_LEADS: 'تعاملات مالية',
            VIEW_ALL: 'عرض الكل',
            NEXT_FOLLOWUP_DATE: 'موعد المتابعة التالية',
            ADD_BUSINESS_LEAD: 'إضافة تعاملات مالية',
            UPDATE_BUSINESS_LEAD: 'تحديث التعاملات المالية',
            SUB_CONTRACTS: 'متعاقد مساعد',
            ADD_SUBCONTRACTS: 'إضافة متعاقدين مساعد',
            UPDATE_SUBCONTRACTS: 'Update Sub-Contract'
        },
        MESSAGE_CENTER: {
            CREATE_GROUP: 'إنشاء مجموعة',
            CLICK_TO_SET_GRP_ICON: 'إضغظ هنا لإضافة صورة للمجموعة',
            ADD_GROUP: 'إضافة مجموعة',
            MEDIA: 'الوسائط',
            CLEAR_CHAT: 'مسح المحادثة',
            DOCS: 'مستندات',
            CLEAR_CHAT_CONFIRMATION: 'هل أنت متأكد أنك تريد حذف هذه المحادثة؟',
            GROUP_INFO: 'معلومات المجموعة',
            VALIDATION: {
                GROUP_NAME: 'أدخل اسم المجموعة'
            },
            CONFIRMATION: {
                Clear_Chat: 'هل أنت متأكد أنك تريد حذف هذه المحادثة؟',
                Delete_Group: 'هل أنت متأكد أنك تريد حذف هذه المجموعة؟'
            },
            PARTICIPANTS: 'المشاركون',
            DELETE_GROUP: 'حذف المجموعة',
            FORWARD_MESSAGE_TO: 'إعادة توجيه الرسالة إلى',
            RECENT_CHATS: 'المحادثات الأخيرة',
            DELETE_FOR_ME: 'الحذف من طرفي',
            DELETE_FOR_EVERYONE: 'حذف للجميع',
            PRIVATE: 'خاص',
            GROUP: 'المجموعات',
            NEW_GROUP: 'مجموعة جديدة',
            TYPE_A_MESSAGE: 'أكتب الرسالة',
            VOICE_MESSAGE: 'رسالة صوتية',
            SEND_MESSAGE: 'أرسل الرسالة',
            FORWARDED: 'تمت إعادة توجيه',
            COPY: 'نسخ',
            REPLY: 'رد',
            FORWARD: 'تحويل',
            THIS_MSG_WAS_DELETED: 'تم حذف هذه الرسالة',
            YOU: 'أنت',
            YOU_DELETED_THIS_MSG: 'لقد حذفت هذه الرسالة',
            CHANGE_GRP_ICON: 'تغيير صورة المجموعة',
            NEW_CHAT: 'محادثة جديدة',
            DELETE_CHAT_CONFIRM: 'هل أنت متأكد أنك تريد حذف المحادثة'
        },
        EMPLOYEE_PROFILE: {
            Supervisor: 'المشرف',
            JOINING_DATE: 'تاريخ الإنضمام',
            SKILL_SET: 'مجموعة المهارات'
        },
        AddEmployee: {
            employeeId: 'رقم الموظف',
            Profile_details: "تفاصيل الملف الشخصي",
            File: "الملف",
            Choose_File: "إختر ملف",
            No_file_chosen: "لم تقم باختيار أي ملف",
            Name: "الإسم",
            Email: "البريد الإلكتروني",
            Nationality: "الجنسية",
            Designation: "المسمى الوظيفي",
            Department: "القسم",
            Username: "اسم المستخدم",
            Password: "كلمة السر",
            User_Rights: "صلاحيات المستخدم",
            Project_Creation: "إنشاء مشروع",
            Project_View: "عرض المشروع",
            Client_View: "عرض العميل",
            Quick_Add: "إضافة سريعة",
            Personal_Data: "البيانات الشخصية",
            Date_of_Birth: "تاريخ الميلاد",
            Gender: "الجنس",
            Male: "ذكر",
            Female: "أنثى",
            Mobile_Number: "رقم الهاتف",
            National_ID_Number: "رقم الهوية الوطنية",
            Passport_Number: "رقم جواز السفر",
            Insurance_ID_Number: "رقم بطاقةالتأمين",
            Visa_Type: "نوع التأشيرة",
            About: "حول",
            Relative_Name: "إسم شخص من الأقرباء",
            Date_of_Hired: "تاريخ التوظيف",
            City: "المدينة",
            National_ID_Expiry: "تاريخ إنتهاء صلاحيةالهويةالوطنية",
            Passport_Expiry: "تاريخ إنتهاء صلاحية جواز السفر",
            Insurance_ID_Expiry: "تاريخ إنتهاء صلاحية هوية التأمين",
            Visa_Expiry: "تاريخ إنتهاء صلاحية التأشيرة ",
            Contact_Number: "رقم التواصل ",
            Add_More: "إضافة المزيد",
            Job_Data: "بيانات الوظيفة ",
            Work_Start_Time: "وقت بدء العمل",
            Work_End_Time: "وقت إنتهاء العمل ",
            Date_of_Joining: "تاريخ الإنضمام ",
            Basic_Salary: "الراتب الأساسي",
            Transportation_Amount: "بدل النقل",
            HRA_Salary: "بدل إيجار المنزل",
            Other_Allowance: "بدلات أخرى",
            Documents: "المستندات",
            Upload_Documents: "رفع المستندات (الحد الأقصى 32 حرف)",
            Document_Details: "تفاصيل المستند ",
            File_Name: "اسم الملف",
            File_Type: "نوع الملف",
            File_Size: "حجم الملف",
            Action: "إجراء",
            Update_Profiles: "تحديث الملفات الشخصية",
            Add_Profiles: "إضافة الملف الشخصي",
            Cancel: "إلغاء",
            Dashboard: 'لوحة القيادة',
            Setup: 'الإعدادات',
            Teams: 'فريق العمل',
            Projects: 'المشاريع',
            Clients: 'العملاء',
            Employee: 'الموظف',
            Contact: 'جهة الإتصال',
            Date_of_joining: 'تاريخ الإنضمام',
            Use_Rights: 'صلاحيات المستخدم',
            Message: 'رسالة',
            Project_ADD: 'إضافة مشروع',
            Project_EDIT: 'تعديل المشروع',
            Project_VIEW: 'عرض المشروع',
            Reset_Password: "تغيير كلمة المرور",
            New_Password: "كلمة مرور جديدة",
            Confirm_Password: 'تأكيد كلمة المرور',
            Add_Employee: 'إضافة موظف',
            Update_Employee: 'تحديث معلومات الموظف',
            Disable_Employee: 'تعطيل الموظف',
            Activate_Employee: 'تفعيل الموظف',
            Social_Media_Links: 'روابط التواصل الإجتماعي',
            Linkedin: 'لينكيد إن',
            Facebook: 'فيس بوك',
            Whatsapp: 'الواتس أب',
            Instagram: 'إنستغرام',
            Twitter: 'تويتر',
            Upload_Excel: 'استيراد ملف إكسل',
            VALIDATION: {
                NAME: 'الإسم مطلوب',
                EMAIL_REQUIRED: 'البريد الالكتروني مطلوب ',
                NATIONALITY: 'الجنسية مطلوبة',
                DEPARTMENT: 'القسم مطلوب',
                DESIGNATION: 'المسمى الوظيفي مطلوب',
                USERNAME: 'اسم المستخدم مطلوب',
                PASSWORD: 'كلمة المرور مطلوبة ',
                EMPLOYEE_ID: 'رقم الموظف مطلوب'
            }
        },
        EmployeeListGrid: {
            Employee: "الموظف",
            Name: "الاسم",
            Contact: "جهة الإتصال",
            Department: "القسم",
            Nationality: "الجنسية",
            Date_of_joining: "تاريخ الإنضمام",
            Date_of_Birth: "تاريخ الميلاد",
            Use_Rights: "صلاحيات المستخدم",
            Message: "رسالة",
            active: "نشط",
            level: "المستوى",
            device: "الجهاز",
            Tasks: "المهام",
            search: "البحث",
            employee_details: 'تفاصيل الموظفين',
            Not_In_Organisation: 'غير موجود في شجرة المؤسسة',
            Add_In_Tree: 'إضافة إلى الشجرة',
            Email_Verification: 'تأكيد بواسطة البريد الالكتروني',
            Verified: 'تم التحقق',
            Not_Verified: 'لم يتم التحقق منه',
            Disabled_by_Admin: 'متوقف من قبل المشرف',
            Internal: 'داخلي',
            External: 'خارجي',
            Rights: 'صلاحيات'
        },
        TaskList: {
            created_by: 'أنشئ بواسطة',
            task_title: 'عنوان المهمة',
            description: 'الوصف',
            project: 'المشروع',
            module: 'النموذج',
            members: 'الأعضاء',
            status: 'الحالة',
            action: 'الإجراء',
            subtasks: 'مهمات فرعية'
        },
        TimeSheetList: {
            project_title: 'عنوان المشروع',
            start_time: 'وقت البدء',
            end_time: 'وقت الإنتهاء',
            total_hours: 'مجموع الساعات'
        },
        SalesPipeLineList: {
            customer_name: 'اسم العميل',
            date: 'التاريخ',
            status: 'الحالة',
            amount: 'المبلغ (درهم)'
        },
        AddTask: {
            project: 'المشروع',
            project_name: 'اسم المشروع',
            module: 'النموذج',
            task_title: 'عنوان المهمة',
            start_date: 'تاريخ البدء',
            end_date: 'تاريخ الانتهاء',
            members: 'أعضاء',
            Priority: 'الأولوية',
            description: 'الوصف',
            upload_document: 'رفع المستندات',
            document_detail: 'تفاصيل المستند',
            file_name: ' اسم الملف',
            file_type: 'نوع الملف',
            file_size: 'حجم الملف',
            action: 'الحالة',
            create_task: 'إنشاء مهمة'
        },
        ProjectList: {
            sl: 'الرقم التسلسلي',
            project_name: 'اسم المشروع',
            client_name: 'اسم العميل',
            created_by: 'أنشئ من قبل',
            created_date: 'تاريخ الإنشاء',
            summary: 'ملخص',
            due_date: 'تاريخ الإستحقاق',
            message: 'رسالة',
            status: 'الحالة',
            task: 'المهمة',
            actions: 'الإجراءات'
        },
        PROJECT_NOTES: {
            NOTES: 'الملاحظات',
            ADD_PROJECT_NOTE: 'إضافة ملاحظات المشروع',
            ADD_NOTE: 'إضافة ملاحظة',
            PROJECT_NOTES: 'ملاحظات المشروع'
        },
        ProjectAdd: {
            title: 'أضف مشروع',
            updateTitle: 'تحديث المشروع',
            name: 'اسم المشروع',
            add_module_cap: 'إضافة نموذج (اكتب اسم النموذج واضغط على إدخال للإضافة)',
            client: 'العميل',
            color: 'اللون',
            start_date: 'تاريخ البدء',
            end_Date: 'تاريخ الإنتهاء',
            members: 'الأعضاء',
            description: 'الوصف',
            module_name: 'اسم النموذج',
            update_module_name: 'تحديث اسم النموذج',
            select_client_contact: 'اختر جهة اتصال العميل',
            add_member: 'إضافة أعضاء',
            client_is_required: 'مطلوب تحديد العميل',
            color_is_required: 'مطلوب اختيار اللون',
            module_name_is_required: 'اسم النموذج مطلوب',
            modules: 'النموذج',
            choose_members: 'إختر أعضاء المشروع',
            choose_owners: 'إختر صاحب المشروع'
        },
        ProjectFolderAdd: {
            member: 'أضف أعضاء لمشاركة هذه الحافظة',
            file: 'أضف أعضاء لمشاركة هذا الملف'
        },
        TimeSheetAdd: {
            title: 'تفاصيل جدول العمل اليومي',
            project: 'المشروع',
            date: 'التاريخ',
            description: 'الوصف',
            time_slot: 'حدد فترة زمنية',
            total_hours: 'مجموع الساعات',
            btn_add_meeting: 'إضافة إجتماع',
            btn_cancel: 'إلغاء',
            end_tm_should_be_greater_than_start_tm: 'يجب اختيار وقت انتهاء متجاوز لوقت البدء المحدد',
            time_slot_overlapped: 'لا يمكنك تحديد هذه القيمة بسبب تداخل الخانات الزمنية المحددة',
            select_start_tm_first: 'يرجى تحديد وقت البدء أولاً',
            select_start_and_end_tm: 'يرجى تحديد وقت البدء والانتهاء',
            add_time_slot: 'إنشاء فترة زمنية'
        },
        MeetingList: {
            meeting_title: 'عنوان الإجتماع',
            location: 'مكان الإجتماع',
            member: 'الأعضاء',
            btn_add: 'إنشاء إجتماع',
            update_meeting: 'تحديث الإجتماع'
        },
        MeetingAdd: {
            location: 'مكان الإجتماع',
            client_member: 'إضافة أعضاء للعميل',
            team_member: 'إضافة أعضاء للإجتماع'
        },
        IssueList: {
            issues: 'المشاكل',
            issue_title: 'عنوان المشكلة',
            module: 'النموذج'
        },
        ADD_ISSUE: {
            add_issue: 'إضافة مشكلة',
            update_issue: 'تحديث المشكلة',
            title_is_required: 'العنوان مطلوب'
        },
        FollowUp: {
            active: 'نشط',
            closed: 'إغلاق',
            create_follow_up: 'إنشاء متابعة',
            update_follow_up: 'تحديث المتابعة',
            follow_up_title: 'عنوان المتابعة',
            follow_up_title_required: 'عنوان المتابعة مطلوب',
            follow_up_date: 'تاريخ المتابعة',
            add_follow_up: 'إضافة المتابعة',
            events: 'الأحداث',
            close: 'إغلاق',
            open: 'فتح',
            CONFIRMATION: {
                close: 'هل أنت متأكد أنك تريد إغلاق من هذه المتابعة؟',
                open: 'هل أنت متأكد أنك تريد فتح هذه المتابعة؟',
                delete_followup: 'هل أنت متأكد أنك تريد حذف هذه المتابعة؟'
            },
            SUCCESS_ALERT: {
                closed: 'تم إغلاق المتابعة بنجاح',
                opened: 'تم فتح المتابعة بنجاح',
                deleted: 'تم حذف المتابعة بنجاح'
            }
        },
        Reminder: {
            reminders: 'التذكيرات',
            create_reminder: 'إنشاء تذكير',
            add_reminder: 'إضافة تذكير',
            remind_me_on: 'ذكرني في',
            reminder: 'تذكير',
            reminder_details: 'تفاصيل التذكير'
        },
        EXTERNAL_LINK: {
            external_links: 'روابط خارجية',
            add_external_link: 'إضافة رابط خارجي',
            link: 'الرابط',
            add_link: 'إضافة رابط',
            update_link: 'تحديث الرابط',
            VALIDATION: {
                link_is_required: 'الرابط مطلوب'
            },
            SUCCESS_MSG: {
                link_added: "تم إضافة الرابط بنجاح",
                deleted: "تم حذف الرابط بنجاح"
            },
            upload_icon: 'اضغط لرفع الشعار',
            delete_confirm: 'هل أنت متأكد من حذف هذا الرابط'
        },
        DEPARTMENTS: {
            departments: 'الأقسام',
            add_department: 'إضافة قسم',
            create_department: 'إنشاء قسم',
            department_name: 'اسم القسم',
            add_department_titles: 'إضافة إسم القسم',
            level_1: 'Level 1',
            level_2: 'Level 2',
            level_3: 'Level 3',
            level_4: 'Level 4',
            VALIDATION: {
                department_name: 'اسم القسم مطلوب',
                department_title: 'Department title cannot be empty'
            },
            SUCCESS_ALERT: {
                department_created: 'تم إنشاء القسم بنجاح ',
                department_deleted: 'تم حذف القسم بنجاح'
            },
            CONFIRMATION: {
                delete: 'هل أنت متأكد أنك تريد حذف هذا القسم؟'
            }
        },
        DESIGNATIONS: {
            designations: 'المسمى الوظيفي',
            add_designation: 'إضافة مسمى وظيفي',
            create_designation: 'إنشاء مسمى وظيفي',
            update_designation: 'تحديث المسمى الوظيفي',
            designation_name: 'اسم المسمى الوظيفي',
            VALIDATION: {
                designation_name: 'اسم المسمى الوظيفي مطلوب'
            },
            SUCCESS_ALERT: {
                designation_created: 'تم إنشاء المسمى الوظيفي بنجاح',
                designation_deleted: 'تم حذف المسمى الوظيفي بنجاح'
            },
            CONFIRMATION: {
                delete: 'هل أنت متأكد أنك تريد حذف هذا المسمى الوظيفي؟'
            }
        },
        COMPANY: {
            pricing_plan: "الباقات والأسعار",
            creation_date: "تاريخ الإنشاء",
            firebase_usage: "المساحة الإجمالية المستخدمة للرسائل",
            usage: "المساحة المستخدمة",
            file_database_usage: "المساحة المستخدمة للملفات",
            database_usage: "المساحة المستخدمة من قاعدة البيانات",
            no_of_users: 'عدد المستخدمين',
            total_users: "إجمالي المستخدمين",
            active_users: "المستخدمين الفعالين",
            remaining_users: "المستخدمون الباقون",
            licence: "الرخصة",
            key: "المفتاح",
            expiration_dt: "تاريخ الإنتهاء",
            employees: "الموظفين",
            validity: "الصلاحية",
            storage: "التخزين",
            database_storage: "مخزن قاعدة البيانات",
            file_storage: "مخزن الملفات",
            message_storage: "مخزن الرسائل",
            add_company_info: "Add Company Info"
        },
        TEAM_NOTICE: {
            ADD_TEAM_NOTICE: "إضافة إشعارات للفريق",
            TEAM_NOTICE: "ملاحظات الفريق",
            TEAM_NOTICE_VALIDATION_MSG: "لا يمكن أن يكون الإشعار فارغاً",
            CLEAR_TEAM_NOTICE: "مسح ملاحظات الفريق",
            DELETE_SUCCESS: 'تم حذف ملاحظات الفريق بنجاح',
            ADDED_SUCCESS: 'تم إضافة إشعار للفريق بنجاح'
        },
        SKILL: {
            ADD_SKILL: "إضافة مهارات",
            SKILL_NAME: "اسم المهارة",
            UPDATE_SKILL: "تحديث المهارة",
            SKILLS: "المهارات",
            ADDED_SUCCESSFULLY: 'تمت إضافة المهارة بنجاح',
            DELETE_CONFIRM: 'هل أنت متأكد من حذف هذه المهارة',
            DELETE_SUCCESS: 'تم حذف المهارة بنجاح'
        },
        EMPLOYMENT_STATUS: {
            ON_DUTY: 'في العمل',
            ON_VACATION: 'إجازة',
            ON_SICK_LEAVE: 'إجازة مرضية',
            ON_BUSINESS_TRIP: 'رحلة عمل',
            ON_TRAINING: 'في تدريب',
            ON_MISSION: 'في مهمة'
        },
        COMMON_CONTENT: {
            welcome: 'مرحبا',
            select: 'إختر',
            general: 'عام',
            description: 'الوصف',
            btn_cancel: 'إلغاء',
            created_by: 'أنشئ من قبل',
            created_on: 'أنشئ في',
            created_date: 'تاريخ الإنشاء',
            created_dt: 'تاريخ الإنشاء',
            start_date: 'تاريخ البدء',
            end_date: 'تاريخ الإنتهاء',
            start_dt: 'تاريخ البدء',
            end_dt: 'تاريخ الإنتهاء',
            open: 'فتح',
            start_time: 'وقت البدء',
            end_time: 'وقت الإنتهاء',
            date: 'التاريخ',
            status: 'الحالة',
            update_status: 'تحديث الحالة',
            reset_status: 'إعادة ضبط الحالة',
            members: 'الأعضاء',
            date_of_Birth: 'تاريخ الميلاد',
            name: 'الإسم',
            actions: 'الإجراء',
            sl: 'الرقم التسلسلي',
            contact_details: 'تفاصيل جهة الإتصال',
            website: 'الموقع الإلكتروني',
            country: 'الدولة',
            contacts: "جهات الإتصال",
            location: 'الموقع',
            address: 'العنوان',
            file: 'الملف',
            details: 'التفاصيل',
            amount: 'المبلغ',
            time: 'الوقت',
            email: 'البريد الإكتروني ',
            mobile_number: 'رقم الهاتف',
            total: 'المجموع',
            search: 'البحث',
            title: 'العنوان',
            notifications: 'الإشعارات',
            phone: 'الهاتف',
            not_available: 'غير متوفر',
            no_device: 'لا يوجد أجهزة',
            edit: 'تعديل',
            disable: 'معطل',
            disabled: 'تعطيل',
            enable: 'مفعل',
            organisation_tree: 'شجرة المؤسسة',
            view: 'عرض',
            update: 'تحديث',
            select_priority: 'تحديد الأولوية',
            attachments: 'المرفقات',
            solved: 'تم حلها',
            unsolved: 'لم تحل',
            mark_as_solved: 'وضع علامة تم حلها',
            mark_as_unsolved: 'وضع علامة لم تحل',
            all: 'الكل',
            full_name: 'الاسم الكامل',
            change_profile_image: 'تغيير صورة الملف الشخصي',
            chat: 'المحادثة',
            ok: 'تم',
            add_members: 'إضافة أعضاء',
            no_more_members: 'لا يوجد أعضاء آخرين',
            search_by_department: 'بحث عن طرق القسم',
            done: 'تم',
            user_profile: 'الملف الشخصي للمستخدم',
            change_password: 'تغيير كلمة السر',
            sign_out: 'تسجيل الخروج',
            current_password: 'كلمة السر الحالية',
            new_password: 'كلمة السر الجديدة',
            confirm_password: 'تأكيد كلمة السر',
            update_password: 'تحديث كلمة المرور',
            view_more: 'عرض المزيد',
            owner: 'المالك',
            filter_by_member: 'فرز بحسب الأعضاء',
            my_clients: 'عملائي',
            my_vendors: 'My Vendors',
            my_tasks: 'مهامي',
            timeline: 'الجدول الزمني',
            not_found: 'لم يتم العثور على',
            confirm: 'تأكيد',
            yes: 'نعم',
            no: 'لا',
            view_description: 'عرض الوصف',
            delete: 'حذف',
            click_to_stop: 'إضغط هنا لإيقاف التسجيل',
            recording: 'تسجيل',
            view_members: 'عرض الأعضاء',
            uploaded_files: 'الملفات المرفوعة',
            success: '!تمت بنجاح',
            error: '!خطأ',
            info: '!معلومات',
            remove: 'إزالة',
            Remove_Tasks: 'حذف المهمات',
            no_right: "ليس لديك الصلاحية لتنفيذ هذا الإجراء",
            no_search_results: 'لا يوجد نتائج مطابقة للبحث',
            no_records: 'لا يوجد سجلات!',
            search_or_add_new: 'للبحث أو إضافة جديدة إضغط على إشارة +',
            are_you_sure: 'هل أنت متأكد ؟',
            share: 'مشاركة',
            link_copied: 'تم نسخ الرابط',
            hours: 'ساعات',
            minutes: 'دقائق',
            hour: 'ساعة',
            hour_and: 'ساعة',
            hours_and: 'ساعات',
            delete_chat: 'حذف المحادثة',
            upload_file: 'رفع ملف',
            designation_not_available: 'المسمى الوظيفي غير متوفر',
            add_image: 'إضافة صورة',
            update_image: 'تحديث الصورة',
            total_amount: 'القيمة الكلية',
            new: 'جديد',
            clear: 'مسح',
            from: 'من',
            comments: 'التعليقات',
            Create: 'إنشاء',
            grid_view: 'عرض بشكل شبكة',
            list_view: 'عرض بشكل قائمة',
            sort: 'ترتيب',
            duration: 'المدة',
            no_new_notifications: 'لايوجد إشعارات جديدة',
            CLICK_ON_MIC_TO_RECORD: 'اضغط على المايك لتسجيل الصوت',
            days: 'أيام',
            VALIDATION: {
                member_is_required: 'تحديد العضو مطلوب',
                start_dt_is_required: 'تاريخ البدء مطلوب',
                end_dt_is_required: 'تاريخ الإنتهاء مطلوب',
                description_is_required: 'الوصف مطلوب',
                please_enter_current_password: 'الرجاء إدخال كلمة المرور الحالية',
                password_strength_alert: 'كلمة المرور يجب أن تتكون من 8-22 حرف وأن تحتوي على الأقل على حرفف واحد كبير ورقم واحد على الأقل و رمز خاص واحد عالأقل مثل @ ,# ,$ , % , &, *...',
                confirm_password: 'تأكيد كلمة المرور الجديدة',
                new_password: 'الرجاء إدخال كلمة المرور الجديدة',
                confirm_password_not_match: '(كلمة المرور) و (تأكيد كلمة) المرور غير متطابقان',
                module_is_required: 'اختيار النموذج مطلوب',
                project_name_is_required: 'اسم المشروع مطلوب',
                project_is_required: 'تحديد المشروع مطلوب',
                title_is_required: 'العنوان مطلوب',
                name_is_required: 'الإسم مطلوب',
                choose_file: 'الرجاء اختيار الملف لرفعه',
                enter_valid_email: 'الرجاء إدخال بريد إلكتروني صحيح',
                organisation_name: 'لطفا إضافة اسم المؤسسة',
                date_is_required: 'تحديد التاريخ مطلوب',
                status: 'اختيار الحالة مطلوب',
                reminder: 'التذكير مطلوب',
                duration: 'مطلوب تحديد المدة',
                project_id: 'رقم المشروع مطلوب',
                select_department: 'الرجاء إختيار قسم أولا'
            },
            CONFIRMATION_ALERT: {
                ARCHIVE_PROJECT: 'هل أنت متأكد أنك تريد أرشفة هذا المشروع؟',
                ACTIVATE_PROJECT: 'هل أنت متأكد أنك تريد تفعيل هذا المشروع؟',
                DELETE_ATTACHMENT: 'هل أنت متأكد أنك تريد حذف هذه المرفقات؟',
                DELETE_TASK: 'هل أنت متأكد أنك تريد حذف هذه المهمة؟',
                MARK_TASK_AS_COMPLETED: 'هل ترغب بالتأشير على إتمام إنجاز هذه المهمة؟',
                DELETE_ISSUE: 'هل أنت متأكد أنك تريد حذف هذه المشكلة؟?',
                CHANGE_PROJECT_STATUS: 'هل أنت متأكد أنك تريد تغيير الحالة إلى ',
                DELETE_PROJECT_NOTE: 'هل أنت متأكد أنك تريد حذف هذه الملاحظة؟',
                ENABLE_EMPLOYEE: 'هل تريد تفعيل هذا الموظف !',
                DISABLE_EMPLOYEE: 'هل تريد تعطيل هذا الموظف',
                DELETE_MEETING: 'هل أنت متأكد أنك تريد حذف هذا الاجتماع؟',
                DELETE_MEETING_MINUTE: 'هل أنت متأكد أنك تريد حذف هذا المحضر؟',
                DELETE_FILE: 'هل أنت متأكد أنك تريد حذف هذا الملف؟',
                DELETE_FOLDER: 'حذف هذه الحافظة سوف يحذف كل الحافظات والملفات الموجودة بداخلها , هل تريد المتابعة؟',
                DELETE_TIMESLOT: 'هل تريد حذف هذه الفترة الزمنية؟',
                DELETE_REMINDER: 'هل أنت متأكد أنك تريد حذف هذا التذكير؟'
            },
            SUCCESS_ALERT: {
                PROJECT_ARCHIVED: 'تمت أرشفة المشروع بنجاح',
                PROJECT_ACTIVATED: 'تم تفعيل المشروع بنجاح',
                PROJECT_CREATED: 'تم إنشاء المشروع بنجاح',
                PROJECT_UPDATED: 'تم تحديث المشروع بنجاح',
                FILE_UPLOADED: 'تم رفع الملف بنجاح',
                ATTACHMENT_DELETED: 'تم حذف المرفقات بنجاح',
                TASK_DELETED: 'تم حذف المهمة بنجاح',
                STATUS_UPDATED: 'تم تحديث الحالة !',
                ISSUE_DELETED: 'تم حذف المشكلة بنجاح',
                ISSUE_ADDED: 'تم إرسال المشكلة بنجاح',
                ISSUE_UPDATED: 'تم تحديث المشكلة بنجاح',
                PROJECT_NOTE_ADDED: 'تمت إضافة الملاحظة بنجاح',
                CREATED_SUCCESSFULLY: 'تم الإنشاء بنجاح',
                UPDATED_SUCCESSFULLY: 'تم التحديث بنجاح',
                DELETED_SUCCESSFULLY: 'تم الحذف بنجاح',
                PASSWORD_RESET: 'تمت إعادة ضبط كلمة المرور بنجاح',
                PROFILE_IMAGE_UPLOADED: 'تم رفع الصورة بنجاح',
                PROFILE_UPDATED: 'تم تحديث الملف الشخصي بنجاح',
                TIME_SHEET_CREATED: 'تم إنشاء جدول العمل اليومي بنجاح',
                MEETING_CREATED: 'تم إنشاء الإجتماع بنجاح',
                MEETING_UPDATED: 'تم تحديث الاجتماع بنجاح',
                MEETING_MINUTE_DELETED: 'تم حذف محضر الاجتماع بنجاح',
                FOLDER_CREATED: 'تم إنشاء الحافظة بنجاح',
                FOLDER_UPDATED: 'تم تحديث الحافظة بنجاح',
                FOLDER_DELETED: 'تم حذف الحافظة بنجاح',
                REMINDER_DELETED: 'تم حذف التذكير بنجاح!',
                REMINDER_CREATED: 'تم إنشاء التذكير بنجاح',
                EMPLOYEE_ADDED: 'تم إضافة الموظف بنجاح',
                EMPLOYEE_UPDATED: 'تم تحديث معلومات الموظف بنجاح',
                EXCEL_UPLOADED: 'تم رفع ملف الإكسل بنجاح',
                EMPLOYMENT_STATUS: 'تم تحديث الحالة بنجاح',
                TASK_FORWARDED: 'تم توجيه المهمة بنجاح'
            },
            ERROR_ALERT: {
                BAD_HAPPENED: 'عذراً, حدث خطأ',
                MIC_NOT_FOUND: 'لم يتم العثور على الميكروفون ',
                IMAGE_UPLOAD_FAILED: 'لم يتم رفع الصورة',
                FILE_UPLOAD_FAILED: 'حدث خطأ أثناء رفع الملف'
            },
            WARNING: {
                FILE_TYPE_EXE: "الملفات من النوع (exe) غير مسموح رفعها",
                FILE_SIZE_LIMIT: "حجم الملف تجاوز الحد المسموح به (25ميغا)"
            },
            NO_DATA: {
                NO_FILES_FOUND: "لايوجد ملفات",
                NO_ISSUES_FOUND: "لم يتم العثور على أى مشكلات"
            }
        },
        NO_DATA: {
            NO_TASK: 'لم يتم العثور على مهمة',
            NO_BUSINESS_LEADS: 'لم يتم العثور على أي عملاء محتملين'
        },
        AUTH: {
            GENERAL: {
                OR: 'أو',
                SUBMIT_BUTTON: 'إرسال',
                NO_ACCOUNT: 'ليس لديك حساب؟',
                SIGNUP_BUTTON: 'إنشاء حساب',
                FORGOT_BUTTON: 'نسيت كلمة المرور',
                BACK_BUTTON: 'عودة للسابق',
                PRIVACY: 'الخصوصية',
                LEGAL: 'القانونية',
                CONTACT: 'جهة الإتصال',
            },
            LOGIN: {
                TITLE: 'تسجيل الدخول',
                BUTTON: 'تسجيل الدخول',
            },
            FORGOT: {
                TITLE: 'نسيت كلمة المرور؟',
                DESC: 'أدخل بريدك الإلكتروني لإعادة ضبط كلمة السر الخاصة بك',
                SUCCESS: 'تمت إعادة ضبط حسابك بنجاح.'
            },
            REGISTER: {
                TITLE: 'إنشاء حساب',
                DESC: 'أدخل معلوماتك الشخصية لإنشاء الحساب',
                SUCCESS: 'تم إنشاء حسابك بنجاح.'
            },
            INPUT: {
                EMAIL: 'البريد الإلكتروني',
                FULLNAME: 'الاسم الكامل',
                PASSWORD: 'كلمة المرور',
                CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
                USERNAME: 'اسم المستخدم'
            }
        }
    }
};
