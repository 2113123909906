// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class MenuConfigService {
	// Public properties

	onConfigUpdated$: Subject<any>;
	onMenuUpdate$: BehaviorSubject<any>;

	// Private properties
	private menuConfig: any;

	/**
	 * Service Constructor
	 */
	constructor() {
		this.menuConfig = {};
		// register on config changed event and set default config
		this.onConfigUpdated$ = new Subject();
		this.onMenuUpdate$ = new BehaviorSubject(this.menuConfig);
	}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config: any) {
		this.menuConfig = config;
		this.onConfigUpdated$.next(this.menuConfig);
	}
}
