import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import * as objectPath from 'object-path';
import configMain from '../../configuration/config';
import { LoaderService } from './service/loader.service';
import { LayoutConfigService } from 'src/app/_metronic/partials/layout';

@Component({
  selector: 'rt-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  loader: boolean = this.layoutConfigService.getConfig('loader.enabled');
  loaderLogo:string;
  loaderType: string;
  isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor(private loaderService: LoaderService,
    private layoutConfigService: LayoutConfigService,) {

    }

  ngOnInit() {
    const loaderConfig = this.layoutConfigService.getConfig('loader');
    this.loaderLogo = configMain.logoLight
		this.loaderType = objectPath.get(loaderConfig, 'type');
  }
}
