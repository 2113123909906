import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TimesheetService {

    constructor(private http: HttpClient) { }

    workReportFilter(data: any): Observable<any> {
        const apiUrl = environment.REST_API_SERVER_URL + 'WorkReport/Filter';
        return this.http.post(apiUrl, data).pipe(
            tap(_ => this.log(`WorkReport=${data}`)),
            catchError(this.handleError<any>('WorkReport'))
        );
    }

    getWorkReportById(id: number | string) {
        const apiUrl = environment.REST_API_SERVER_URL + `WorkReport/${id}`;
        return this.http.get(apiUrl).pipe(
            catchError(this.handleError<any>('WorkReport'))
        );
    }

    workReportInsert(data: any): Observable<any> {
        const apiUrl = environment.REST_API_SERVER_URL + 'WorkReport/Insert';
        return this.http.post(apiUrl, data);
    }

    workReportBulkInsert(data: any): Observable<any> {
        const apiUrl = environment.REST_API_SERVER_URL + 'WorkReport/BulkInsert';
        return this.http.post(apiUrl, data);
    }

    workReportUpdate(data: any, id): Observable<any> {
        const api_url = environment.REST_API_SERVER_URL + 'WorkReport/Update?id=' + id;
        return this.http.put(api_url, data);
    }

    addWorkReportNote(data: FormData): Observable<any> {
        const api_url = environment.REST_API_SERVER_URL + 'WorkReportNote/Insert';
        return this.http.post(api_url, data);
    }

    deleteWorkReport(id: number): Observable<any> {
        return this.http.delete(environment.REST_API_SERVER_URL + `WorkReport/Delete?id=${id}`);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        //this.messageService.add(`AcGroupService: ${message}`);
    }
}
