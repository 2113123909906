// USA
export const locale = {
  "currentTheme": "ltr",
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      Dashboard: 'Dashboard',
      Setup: 'Setup',
      Teams: 'Team',
      Projects: 'Projects',
      Clients: 'Clients',
      Chat: 'Message Center',
      My_page: 'My page',
      HOME: 'Home',
      Message_History: 'Chat History',
      history: 'History'
    },
    DASHBOARD: {
      PENDING_TASKS: 'Pending Tasks',
      UPCOMING_MEETINGS: 'Upcoming Meetings',
      ACTIVE_PROJECTS: 'Active Projects',
      ACTIVE_FOLLOWUPS: 'Active Follow-ups',
      TODAY_MEETINGS: 'Today Meetings',
      TODAYS_ACTIVITY: "Today's Activity",
      NO_ACTIVITIES_TODAY: "No activities today",
      TASKS: 'Tasks',
      ASSIGNED: 'Assigned',
      CREATED: 'Created',
      ADD_TASK: 'Add Task',
      ADD_MEETING: 'Add Meeting',
      FILES: 'Files',
      FOLLOW_UPS: 'Follow-ups',
      TIMESHEET: 'Time Sheet',
      CREATE_TIMESHEET: 'Create Time Sheet',
      ADD_FOLLOWUP: 'Add Follow Up',
      CREATE: 'Create',
      PROJECT_TASKS: 'Project Tasks',
      MEETINGS: 'Meetings',
      ADD_REMINDER: 'Add Reminder',
      EMPLOYEE_FILES: 'My Files',
      PROJECT_FILES: 'Project Files',
      CLIENT_FILES: 'Client Files'
    },
    TEAMS: {
      STATUS: {
        ACTIVE: 'Active',
        INACTIVE: 'Inactive'
      },
      MY_TEAM: 'My Team',
      TEAM_MEMBER: 'Team Member',
      UNASSIGNED_EMPLOYEE_LIST: 'UnAssigned Employee List',
      EMPLOYEE_VACANT: 'Employee Vacant',
      ADD_NEW: 'Add New',
      MARK_AS_ADMIN: 'Mark this employee as Admin'
    },
    PROJECTS: {
      STATUS: {
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Completed'
      },
      ARCHIVE: 'Archive',
      UNARCHIVE: 'Unarchive',
      FILTER_BY_STATUS: 'Filter By Status',
      ACTIVATE: 'Activate',
      CHANGE_STATUS: 'Change Status',
      PROJECT_TASKS: 'Project tasks',
      GANTT_CHART: 'Gantt Chart',
      PROJECT_DETAILS: 'Project details',
      PROJECT_ID: 'Project Id',
      MILESTONES: 'Milestones',
      ADD_MILESTONE: 'Add Milestone',
      FINANCE: 'Finance',
      PROJECT_MILESTONES: 'Project Milestones',
      MILESTONE_ADDED_SUCCESSFULLY: 'Successfully added milestone',
      DRAG_TASKS: 'Drag and drop the task here to bind with milestone',
      MILESTONE_ALERT1: 'Milestone start date and end date should be in between project start date and end date',
      MILESTONE_ALERT2: "and shouldn't be overlap with other milestone dates.",
      PROJECT_GOALS: "Project Goals",
      ADD_PROJECT_GOAL: "Add project goal",
      UPDATE_PROJECT_GOAL: "Update project goal",
      PROJECT_GOAL: "Project Goal",
      PROJECT_GOAL_DELETED: 'Successfully deleted project goal',
      CONFIRM_DELETE_PROJECT_GOAL: 'Are you sure you want to delete this project goal',
      ADD_TASK_TYPE: 'Add task type(max 32 characters)',
      DEPARTMENT_CHANGE_CONFIRM: 'Are you sure you want to change the department? All the project owners you added will be removed',
      CANNOT_UPDATE_OWNER: "This project is live, You can't update owners",
      CANNOT_UPDATE_DEPARTMENT: "This project is live, You can't update the department",
      CANNOT_UPDATE_PROJECT_NAME: "This project is live, You can't update the Project Name",
      CANNOT_UPDATE_PROJECT_ID: "This project is live, You can't update the Project ID",
      CANNOT_CREATE_MILESTONE1: "This project is not live, You can't create milestone",
      CANNOT_CREATE_MILESTONE2: "This project is not active, You can't create milestone",
      NOT_LIVE: "Not Live",
      CHOOSE_VENDOR: 'Choose a project vendor',
      ADD_TASK_TYPE_HINT: 'Type the task type and click + to add',
      ADD_MODULE_HINT: 'Type module name and click + to add'
    },
    TASKS: {
      STATUS: {
        REQUESTED: 'Requested',
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Completed',
        NOT_STARTED: 'Not Started',
        WORKING_ON: 'Working On',
        SUBMITTED_FOR_REVIEW: 'Submitted'
      },
      ACTION_STATUS: {
        MARK_AS_COMPLETED: 'Mark As Completed',
        WAITING_FOR_COMPLETION: 'Waiting for Completion',
        COMPLETED: 'Completed',
        WAITING_FOR_APPROVAL: 'Waiting for approval',
        START: 'Start'
      },
      PRIORITY: {
        LOW: 'Low',
        MEDIUM: 'Medium',
        HIGH: 'High',
        URGENT: 'Urgent',
        VERY_URGENT: 'Very Urgent'
      },
      TASK: 'Task',
      TASK_DETAILS: 'Task Details',
      ADD_SUB_TASK: 'Add Sub Task',
      UPDATE_SUB_TASK: 'Update Sub Task',
      UPDATE_TASK: 'Update Task',
      SUB_TASK_TITLE: 'Sub Task Title',
      ASSIGN_TO_ME: 'Assign to me',
      TASKS_ASSIGNED: 'Tasks Assigned',
      TASKS_CREATED: 'Tasks Created',
      VIEW_TASK: 'View Task',
      NEW_UPDATES: 'New Updates', 
      UPDATES: 'Updates',
      ACTIVITIES: 'Activities',
      CONFIDENTIAL_TASK: 'Confidential Task',
      SUCCESS_MESSAGE: {
        TASK_CREATED: 'Task created successfully',
        TASK_UPDATED: 'Task updated successfully'
      }
    },
    TASK_STATUS_MESSAGE: {
       NOT_STARTED: 'The task is not started yet',
       INPROGRESS: 'The task is in progress',
       SUBMITTED: 'The task is Submitted',
       COMPLETED: 'The task is  Completed'
    },
    TASK_ACTIVITY: {
      ADD_ACTIVITY: "Add Activity",
      ADD: 'Add',
      ADDED_SUCCESSFULLY: 'Successfully added Activity',
      NO_ACTIVITY: 'Please add activity',
      TASK_ACTIVITY: 'Task Activity',
      DELETED_SUCCESSFULLY: 'Successfully deleted Activity',
      CONFIRM_DELETE: 'Are you sure, You want to delete this Activity?',
      CONFIRM_COMPLETE: 'Are you sure, You want to mark this activity as completed',
      COMPLETED_SUCCESSFULLY: 'Successfully completed Activity'
    },
    MEETINGS: {
       MEETING: 'Meeting',
       TODAY: 'Today',
       PREVIOUS: 'Previous',
       UP_COMING: 'Upcoming',
       LATER: 'Later',
       ONLINE_MEETING: 'Online Meeting',
       OFFLINE_MEETING: 'Offline Meeting',
       MEMBER: 'Member',
       NO_MEETING: 'No meetings found today',
       MEETING_DATE: 'Meeting Date',
       MEETING_TIME: 'Meeting Time',
       COPY_MEETING_LINK: 'Copy Meeting Link',
       SHARE_MEETING_LINK: 'Share Meeting Link',
       VIEW_RECORDING: 'View Recording',
       DOWNLOAD_RECORDING: 'Download Recording',
       MINUTES_OF_MEETING: 'Minutes of Meeting',
       ADD_MINUTES_OF_MEETING: 'Add Minutes of Meeting',
       ONLINE: 'Online',
       OFFLINE: 'Offline',
       DURATION_HOUR: 'Duration Hour',
       DURATION_MINUTE: 'Duration Minute',
       START_MEETING: 'Start Meeting',
       JOIN_MEETING: 'Join Meeting',
       MEETING_AGENDA: 'Meeting Agenda'
    },
    EVENTS: {
      EVENT: 'Event',
      ADD_EVENT: 'Add Event',
      EVENTS: 'Events'
    },
    FILES: {
      EMPLOYEE_FILES: 'Employee Files',
      PROJECT_FILES: 'Project Files',
      CLIENT_FILES: 'Client Files',
      CREATE_FOLDER: 'Create Folder',
      CREATE_FILES: 'Create File',
      UPDATE_FOLDER: 'Update Folder',
      LAST_MODIFIED: 'Last Modified',
      UPDATE_FILE: 'Update File',
      FILE_UPDATED: 'Successfully updated file'
    },
    CLIENT: {
      ADD_CLIENT: 'Add Client',
      ADD_VENDOR: 'Add Vendor',
      UPDATE_VENDOR: 'Update Vendor',
      ORGANISATION_NAME: 'Organisation Name',
      TOTAL_PIPELINE: 'Total Pipeline',
      UPDATE_CLIENT: 'Update Client',
      ADD_LOGO: 'Add Logo',
      UPDATE_LOGO: 'Update Logo',
      SALE_AMOUNT: 'Sale Amount',
      SALES_PIPELINE: 'Sales Pipeline',
      CLIENT_CONTACTS: 'Client Contacts',
      ADD_CLIENT_CONTACT: 'Add Client Contact',
      UPDATE_CLIENT_CONTACT: 'Update Client Contact',
      ADD_CONTACT: 'Add Contact',
      UPDATE_CONTACT: 'Update Contact',
      ADD_SALES_PIPELINE: 'Add Sales Pipeline',
      CREATE_SALES_PIPELINE: 'Create Sales Pipeline',
      UPDATE_SALES_PIPELINE: 'Update Sales Pipeline',
      VENDOR: 'Vendor',
      VENDORS: 'Vendors',
      CLIENT: 'Client',
      MY_PRIVATE_CLIENT: 'My Private Client',
      MY_PRIVATE_VENDOR: 'My Private Vendor',
      BUSINESS_LEADS: 'Business Leads',
      VIEW_ALL: 'View All',
      NEXT_FOLLOWUP_DATE: 'Next followup date',
      ADD_BUSINESS_LEAD: 'Add Business Lead',
      UPDATE_BUSINESS_LEAD: 'Update Business Lead',
      SUB_CONTRACTS: 'Sub Contracts',
      ADD_SUBCONTRACTS: 'Add Sub-Contracts',
      UPDATE_SUBCONTRACTS: 'Update Sub-Contract'
    },
    MESSAGE_CENTER: {
      CREATE_GROUP: 'Create Group',
      CLICK_TO_SET_GRP_ICON: 'Click to set a group icon',
      ADD_GROUP: 'Add Group',
      MEDIA: 'Media',
      CLEAR_CHAT: 'Clear Chat',
      DOCS: 'DOCS',
      CLEAR_CHAT_CONFIRMATION: 'Are you sure you want to clear this chat',
      GROUP_INFO: 'Group Info',
      VALIDATION: {
        GROUP_NAME: 'Enter group name'
      },
      CONFIRMATION: {
        Clear_Chat: 'Are you sure you want to clear this chat',
        Delete_Group: 'Are you sure you want to delete this group?'
      },
      PARTICIPANTS: 'Participants',
      DELETE_GROUP: 'Delete Group',
      FORWARD_MESSAGE_TO: 'Forward Message to',
      RECENT_CHATS: 'Recent Chats',
      DELETE_FOR_ME: 'Delete For Me',
      DELETE_FOR_EVERYONE: 'Delete For Everyone',
      PRIVATE: 'Private',
      GROUP: 'Group',
      NEW_GROUP: 'New Group',
      TYPE_A_MESSAGE: 'Type a message',
      VOICE_MESSAGE: 'Voice Message',
      SEND_MESSAGE: 'Send Message',
      FORWARDED: 'Forwarded',
      COPY: 'Copy',
      REPLY: 'Reply',
      FORWARD: 'Forward',
      THIS_MSG_WAS_DELETED: 'This message was deleted',
      YOU: 'You',
      YOU_DELETED_THIS_MSG: 'You deleted this message',
      CHANGE_GRP_ICON: 'Change Group Icon',
      NEW_CHAT: 'New Chat',
      DELETE_CHAT_CONFIRM: 'Are you sure you want to delete this chat?'
    },
    EMPLOYEE_PROFILE: {
      Supervisor: 'Supervisor',
      JOINING_DATE: 'Joining Date',
      SKILL_SET: 'Skill Set'
    },
    AddEmployee: {
      employeeId: 'Employee Id',
      Profile_details: 'Profile details',
      File: 'File',
      Choose_File: 'Choose File',
      No_file_chosen: 'No file chosen',
      Name: 'Name',
      Email: 'Email',
      Nationality: 'Nationality',
      Designation: 'Designation',
      Department: 'Department',
      Username: 'Username',
      Password: 'Password',
      User_Rights: 'User Rights',
      Project_Creation: 'Project Creation',
      Project_View: 'Project View',
      Client_View: 'Client View',
      Quick_Add: 'Quick Add',
      Personal_Data: 'Personal Data',
      Date_of_Birth: 'Date of Birth',
      Gender: 'Gender',
      Male: 'Male',
      Female: 'Female',
      Mobile_Number: 'Mobile Number',
      National_ID_Number: 'National ID Number',
      Passport_Number: 'Passport Number',
      Insurance_ID_Number: 'Insurance ID Number',
      Visa_Type: 'Visa Type',
      About: 'About',
      Relative_Name: 'Relative Name',
      Date_of_Hired: 'Date of Hired',
      City: 'City',
      National_ID_Expiry: 'National ID Expiry',
      Passport_Expiry: 'Passport Expiry',
      Insurance_ID_Expiry: 'Insurance ID Expiry',
      Visa_Expiry: 'Visa Expiry',
      Contact_Number: 'Contact Number',
      Add_More: 'Add More',
      Job_Data: 'Job Data',
      Work_Start_Time: 'Work Start Time',
      Work_End_Time: 'Work End Time',
      Date_of_Joining: 'Date of Joining',
      Basic_Salary: 'Basic Salary',
      Transportation_Amount: 'Transportation Amount',
      HRA_Salary: 'HRA Salary',
      Other_Allowance: 'Other Allowance',
      Documents: 'Documents',
      Upload_Documents: 'Upload Documents(Max 25mb)',
      Document_Details: 'Document Details',
      File_Name: 'File Name',
      File_Type: 'File Type',
      File_Size: 'File Size',
      Action: 'Action',
      Update_Profiles: 'Update Profile',
      Add_Profiles: 'Add Profile',
      Cancel: 'Cancel',
      Dashboard: 'Dashboard',
      Setup: 'Setup',
      Teams: 'Teams',
      Projects: 'Projects',
      Clients: 'Clients',
      Employee: 'Employee',
      Contact: 'Contact',
      Date_of_joining: 'Date of joining',
      Use_Rights: 'User Rights',
      Message: 'Message',
      Project_ADD: 'ADD Project',
      Project_EDIT: 'EDIT Project',
      Project_VIEW: 'VIEW Project',
      Reset_Password: 'Reset Password',
      New_Password: 'New Password',
      Confirm_Password: 'Confirm Password',
      Add_Employee: 'Add Employee',
      Update_Employee: 'Update Employee',
      Disable_Employee: 'Disable Employee',
      Activate_Employee: 'Activate Employee',
      Social_Media_Links: 'Social Media Links',
      Linkedin: 'Linkedin',
      Facebook: 'Facebook',
      Whatsapp: 'Whatsapp',
      Instagram: 'Instagram',
      Twitter: 'Twitter',
      Upload_Excel: 'Import Excel',
      VALIDATION: {
        NAME: 'Name is required',
        EMAIL_REQUIRED: 'Email is required',
        NATIONALITY: 'Nationality is required',
        DEPARTMENT: 'Department is required',
        DESIGNATION: 'Designation is required',
        USERNAME: 'Username is required',
        PASSWORD: 'Password is required',
        EMPLOYEE_ID: 'Employee Id is required'
      }
    },
    EmployeeListGrid: {
      Employee: 'Employee',
      Name: 'Name',
      Contact: 'Contact',
      Department: 'Department',
      Nationality: 'Nationality',
      Date_of_joining: 'Date of joining',
      Date_of_Birth: 'Date of Birth',
      Use_Rights: 'User Rights',
      Message: 'Message',
      active: 'Active',
      level: 'Level',
      device: 'Device',
      Tasks: 'Tasks',
      search: 'Search',
      employee_details: 'Employee Details',
      Not_In_Organisation: 'Not in organisation tree',
      Add_In_Tree: 'Add in Tree',
      Email_Verification: 'Email Verification',
      Verified: 'Verified',
      Not_Verified: 'Not Verified',
      Disabled_by_Admin: 'Disabled by Admin',
      Internal: 'Internal',
      External: 'External',
      Rights: 'Rights'
    },
    TaskList: {
      created_by: 'Created By',
      task_title: 'Task Title',
      description: 'Description',
      project: 'Project',
      module: 'Module',
      members: 'Members',
      status: 'Status',
      action: 'Action',
      subtasks: 'Sub Tasks'
    },
    TimeSheetList: {
      project_title: 'Project Title',
      start_time: 'Start Time',
      end_time: 'End Time',
      total_hours: 'Total Hours'
    },
    SalesPipeLineList: {
      customer_name: 'Customer Name',
      date: 'Date',
      status: 'Status',
      amount: 'Amount(AED)'
    },
    AddTask: {
      project: 'Project',
      project_name: 'Project Name',
      module: 'Module',
      task_title: 'Task Title',
      start_date: 'Start Date',
      end_date: 'End Date',
      members: 'Members',
      Priority: 'Priority',
      description: 'Description',
      upload_document: 'Upload Documents',
      document_detail: 'Document Details',
      file_name: ' File Name',
      file_type: 'File Type',
      file_size: 'File Size',
      action: 'Action',
      create_task: 'Create Task'
    },
    ProjectList: {
      sl: 'Sl',
      project_name: 'Project Name',
      client_name: 'Client Name',
      created_by: 'Created By',
      created_date: 'Created Date',
      summary: 'Summary',
      due_date: 'Due Date',
      message: 'Message',
      status: 'Status',
      task: 'Task',
      actions: 'Actions'
    },
    PROJECT_NOTES: {
      NOTES: 'Notes',
      ADD_PROJECT_NOTE: 'Add Project Note',
      ADD_NOTE: 'Add Note',
      PROJECT_NOTES: 'Project Notes'
    },
    ProjectAdd: {
      title: 'Add Project',
      updateTitle: 'Update Project',
      name: 'Project Name',
      add_module_cap: 'Add Module (Max 32 characters)',
      client: 'Client',
      color: 'Color',
      start_date: 'Start Date',
      end_Date: 'End Date',
      members: 'Members',
      description: 'Description',
      module_name: 'Module Name',
      update_module_name: 'Update Module Name',
      select_client_contact: 'Select Client Contact',
      add_member: 'Add Member',
      client_is_required: 'Client is required',
      color_is_required: 'Color is required',
      module_name_is_required: 'Module name is required',
      modules: 'Modules',
      choose_members: 'Choose project members',
      choose_owners: 'Choose project owners'
    },
    ProjectFolderAdd: {
      member: 'add member to share this folder',
      file: 'add member to share file'
    },
    TimeSheetAdd: {
      title: 'Time Sheet Details',
      project: 'Project',
      date: 'Date',
      description: 'Description',
      time_slot: 'Select Time Slot',
      total_hours: 'Total Hours',
      btn_add_meeting: 'Add Meeting',
      btn_cancel: 'Cancel',
      end_tm_should_be_greater_than_start_tm: 'End time should be greater than start time',
      time_slot_overlapped: 'You can not select this value due to overlap the already selected slots',
      select_start_tm_first: 'Please Select Start Time First',
      select_start_and_end_tm: 'Please Select Start and End Time',
      add_time_slot: 'Add time slot'
    },
    MeetingList: {
      meeting_title: ' Meeting Title',
      location: 'Location',
      member: 'Members',
      btn_add: 'Create Meeting',
      update_meeting: 'Update Meeting'
    },
    MeetingAdd: {
      location: 'Location',
      client_member: 'Client Member',
      team_member: 'Team Member'
    },
    IssueList: {
      issues: 'Issues',
      issue_title: 'Issue Title',
      module: 'Module'
    },
    ADD_ISSUE: {
      add_issue: 'Add Issue',
      update_issue: 'Update Issue',
      title_is_required: 'Title is required'
    },
    FollowUp: {
      active: 'Active',
      closed: 'Closed',
      create_follow_up: 'Create Follow Up',
      update_follow_up: 'Update Follow Up',
      follow_up_title: 'Follow Up Title',
      follow_up_title_required: 'Follow Up Title Required',
      follow_up_date: 'Follow Up Date',
      add_follow_up: 'Add Follow Up',
      events: 'Events',
      close: 'Close',
      open: 'Open',
      CONFIRMATION: {
        close: 'Are you sure you want to close this follow-up?',
        open: 'Are you sure you want to open this follow-up?',
        delete_followup: 'Are you sure you want to delete this follow-up?'
      },
      SUCCESS_ALERT: {
        closed: 'Follow-up closed successfully',
        opened: 'Follow-up opened successfully',
        deleted: 'Follow-up deleted successfully'
      }
    },
    Reminder: {
      reminders: 'Reminders',
      create_reminder: 'Create Reminder',
      add_reminder: 'Add Reminder',
      remind_me_on: 'Remind me on',
      reminder: 'Reminder',
      reminder_details: 'Reminder Details'
    },
    EXTERNAL_LINK: {
      external_links: 'External Links',
      add_external_link: 'Add External Link',
      link: 'Link',
      add_link: 'Add Link',
      update_link: 'Update Link',
      VALIDATION: {
        link_is_required: 'Link is required',
      },
      SUCCESS_MSG: {
        link_added: "Successfully added link",
        deleted: "Successfully deleted external Link"
      },
      upload_icon: 'Click to upload an Icon',
      delete_confirm: 'Are you sure,You want to delete this link'
    },
    DEPARTMENTS: {
      departments: 'Departments',
      add_department: 'Add Department',
      create_department: 'Create Department',
      update_department: 'Update Department',
      department_name: 'Department Name',
      add_department_titles: 'Add Department Titles',
      level_1: 'Level 1',
      level_2: 'Level 2',
      level_3: 'Level 3',
      level_4: 'Level 4',
      VALIDATION: {
        department_name: 'Department Name is required',
        department_title: 'Department title cannot be empty'
      },
      SUCCESS_ALERT: {
        department_created: 'Created Department Successfully',
        department_deleted: 'Successfully deleted department'
      },
      CONFIRMATION: {
          delete: 'Are you sure you want to delete this Department?'
      }
    },
    DESIGNATIONS: {
      designations: 'Designations',
      add_designation: 'Add Designation',
      create_designation: 'Create Designation',
      update_designation: 'Update Designation',
      designation_name: 'Designation Name',
      VALIDATION: {
        designation_name: 'Designation name is required'
      },
      SUCCESS_ALERT: {
        designation_created: 'Successfully created designation',
        designation_deleted: 'Successfully deleted designation'
      },
      CONFIRMATION: {
        delete: 'Are you sure you want to delete this Designation?'
      }
    },
    COMPANY: {
      pricing_plan: "Pricing Plan",
      creation_date: "Creation Date",
      firebase_usage: "Total Storage Used For Messaging",
      usage: "Usage",
      file_database_usage: "File Database Usage",
      database_usage: "Database Usage",
      no_of_users: 'Number of Users',
      total_users: "Total Users",
      active_users: "Active Users",
      remaining_users: "Remaining Users",
      licence: "Licence",
      key: "Key",
      expiration_dt: "Expiration Date",
      employees: "Employees",
      validity: "Validity",
      storage: "Storage",
      database_storage: "Database Storage",
      file_storage: "File Storage",
      message_storage: "Message Storage",
      add_company_info: "Add Company Info"
    },
    TEAM_NOTICE: {
      ADD_TEAM_NOTICE: "Add Team Notice",
      TEAM_NOTICE: "Team Notice",
      TEAM_NOTICE_VALIDATION_MSG: "Team notice can't be empty",
      CLEAR_TEAM_NOTICE: "Clear Team Notice",
      DELETE_SUCCESS: 'Successfully cleared team notice',
      ADDED_SUCCESS: 'Successfully added team notice'
    },
    SKILL: {
        ADD_SKILL: "Add Skill",
        SKILL_NAME: "Skill Name",
        UPDATE_SKILL: "Update Skill",
        SKILLS: "Skills",
        ADDED_SUCCESSFULLY: 'Successfully added skill',
        DELETE_CONFIRM: 'Are you sure,You want to delete this skill',
        DELETE_SUCCESS: 'Successfully deleted Skill'
    },
    EMPLOYMENT_STATUS: {
        ON_DUTY: 'On Duty',
        ON_VACATION: 'Vacation',
        ON_SICK_LEAVE: 'Sick leave',
        ON_BUSINESS_TRIP: 'Business trip',
        ON_TRAINING: 'Training',
        ON_MISSION: 'Mission'
    },
    COMMON_CONTENT: {
      welcome: 'Welcome',
      select: 'Select',
      general: 'General',
      description: 'Description',
      btn_cancel: 'Cancel',
      created_by: 'Created By',
      created_on: 'Created On',
      created_date: 'Created Date',
      created_dt: 'Created Dt',
      start_date: 'Start Date',
      end_date: 'End Date',
      start_dt: 'Start Dt',
      end_dt: 'End Dt',
      open: 'Open',
      start_time: 'Start Time',
      end_time: 'End Time',
      date: 'Date',
      status: 'Status',
      update_status: 'Update Status',
      reset_status: 'Reset Status',
      members: 'Members',
      date_of_Birth: 'Date of Birth',
      name: 'Name',
      actions: 'Actions',
      sl: 'Sl',
      contact_details: 'Contact Details',
      website: 'Website',
      country: 'Country',
      contacts: 'Contacts',
      location: 'Location',
      address: 'Address',
      file: 'File',
      details: 'Details',
      amount: 'Amount',
      time: 'Time',
      email: 'Email',
      mobile_number: 'Mobile Number',
      total: 'Total',
      search: 'Search',
      title: 'Title',
      notifications: 'Notifications',
      phone: 'Phone', 
      not_available: 'Not Available',
      no_device: 'No Device', 
      edit: 'Edit',
      disable: 'Disable',
      disabled: 'Disabled',
      enable: 'Enable',
      organisation_tree: 'Organisation Tree',
      view: 'View',
      update: 'Update',
      select_priority: 'Select Priority',
      attachments: 'Attachments',
      solved: 'Solved',
      unsolved: 'Unsolved',
      mark_as_solved: 'Mark as Solved',
      mark_as_unsolved: 'Mark as Unsolved',
      all: 'All', 
      full_name: 'Full Name',
      change_profile_image: 'Change Profile Image',
      chat: 'Chat',
      ok: 'Ok',
      add_members: 'Add Members',
      no_more_members: 'No More Members',
      search_by_department: 'Search by Department',
      done: 'Done',
      user_profile: 'User Profile',
      change_password: 'Change Password',
      sign_out: 'Sign Out',
      current_password: 'Current Password',
      new_password: 'New Password',
      confirm_password: 'Confirm Password',
      update_password: 'Update Password',
      view_more: 'View More',
      owner: 'Owner',
      filter_by_member: 'Filter By Member',
      my_clients: 'My Clients',
      my_vendors: 'My Vendors',
      my_tasks: 'My Tasks',
      timeline: 'Timeline',
      not_found: 'Not Found',
      confirm: 'Confirm',
      yes: 'Yes',
      no: 'No',
      view_description: 'View Description',
      delete: 'Delete',
      click_to_stop: 'Click to stop recording',
      recording: 'Recording',
      view_members: 'View Members',
      uploaded_files: 'Uploaded Files',
      success: 'Success!',
      error: 'Error!',
      info: 'Info!',
      remove: 'Remove',
      Remove_Tasks: 'Remove Tasks',
      no_right: "You don't have right to perform this action",
      no_search_results: 'No items match your search',
      no_records: 'No Records found',
      search_or_add_new: 'Search or Add new by clicking plus button',
      are_you_sure: 'Are you sure?',
      share: 'Share',
      link_copied: 'Link Copied',
      hours: 'Hours',
      minutes: 'Minutes',
      hour: 'Hour',
      hour_and: 'Hour and',
      hours_and: 'Hours and',
      delete_chat: 'Delete Chat',
      upload_file: 'Upload File',
      designation_not_available: 'Designation not available',
      add_image: 'Add Image',
      update_image: 'Update Image',
      total_amount: 'Total Amount',
      new: 'New',
      clear: 'Clear',
      from: 'From',
      comments: 'Comments',
      Create: 'Create',
      grid_view: 'Grid View',
      list_view: 'List View',
      sort: 'sort',
      duration: 'Duration',
      no_new_notifications: 'No new notifications',
      CLICK_ON_MIC_TO_RECORD: 'Click on mic to record voice',
      days: 'Days',
      VALIDATION: {
        member_is_required: 'Member is required',
        start_dt_is_required: 'Start date is required',
        end_dt_is_required: 'End Date is required',
        description_is_required: 'Description is required',
        please_enter_current_password: 'Please enter current password',
        password_strength_alert: 'Password must be 8-22 characters long with at least one numeric, one upper case letter and one special character such as @, #, $, %, &, *',
        confirm_password: 'Confirm your new password',
        new_password: 'Please enter new password',
        confirm_password_not_match: "'Passsword' and 'Confirm Password' didn't match.",
        module_is_required: 'Module is required',
        project_name_is_required: 'Project name is required',
        project_is_required: 'Project is required',
        title_is_required: 'Title is required',
        name_is_required: 'Name is required',
        choose_file: 'Please choose a file to upload',
        enter_valid_email: 'Enter valid email',
        organisation_name: 'Please enter organization name',
        date_is_required: 'Date is required',
        status: 'Status is required',
        reminder: 'Reminder is required',
        duration: 'Duration is required',
        project_id: 'Project Id is required',
        select_department: 'Please select department first'
      },
      CONFIRMATION_ALERT: {
        ARCHIVE_PROJECT: 'Are you sure you want to archive this project',
        ACTIVATE_PROJECT: 'Are you sure you want to activate this project',
        DELETE_ATTACHMENT: 'Are you sure you want to delete this Attachment?',
        DELETE_TASK: 'Are you sure you want to delete this task?',
        MARK_TASK_AS_COMPLETED: 'Do you want to mark this task completed?',
        DELETE_ISSUE: 'Are you sure you want to delete this issue?',
        CHANGE_PROJECT_STATUS: 'Are you sure you want to change the project status to ',
        DELETE_PROJECT_NOTE: 'Are you sure you want to delete this note?',
        ENABLE_EMPLOYEE: 'You want to enable this employee!',
        DISABLE_EMPLOYEE: 'You want to disable this employee!',
        DELETE_MEETING: 'Are you sure you want to delete this meeting?',
        DELETE_MEETING_MINUTE: 'Are you sure you want to delete this minute?',
        DELETE_FILE: 'Are you sure you want to delete this file?',
        DELETE_FOLDER: 'Deleting the folder will delete all the folders and files inside it, Do you want to continue?',
        DELETE_TIMESLOT: 'Do you want to delete this time slot?',
        DELETE_REMINDER: 'Are you sure you want to delete this reminder?'
      },
      SUCCESS_ALERT: {
        PROJECT_ARCHIVED: 'Project archived successfully',
        PROJECT_ACTIVATED: 'Project activated successfully',
        PROJECT_CREATED: 'Project created successfully',
        PROJECT_UPDATED: 'Project updated successfully',
        FILE_UPLOADED: 'File uploaded successfully',
        ATTACHMENT_DELETED: 'Attachment deleted successfully',
        TASK_DELETED: 'Successfully deleted task',
        STATUS_UPDATED: 'Status Updated!',
        ISSUE_DELETED: 'Issue deleted successfully',
        ISSUE_ADDED: 'Issue submitted successfully',
        ISSUE_UPDATED: 'Issue updated successfully',
        PROJECT_NOTE_ADDED: 'Project note added successfully',
        CREATED_SUCCESSFULLY: 'Created successfully',
        UPDATED_SUCCESSFULLY: 'Updated successfully',
        DELETED_SUCCESSFULLY: 'Deleted Successfully',
        PASSWORD_RESET: 'Successfully reset password.',
        PROFILE_IMAGE_UPLOADED: 'Successfully uploaded profile image',
        PROFILE_UPDATED: 'Profile Updated Successfully',
        TIME_SHEET_CREATED: 'Time sheet created successfully',
        MEETING_CREATED: 'Meeting created successfully',
        MEETING_UPDATED: 'Meeting updated successfully',
        MEETING_MINUTE_DELETED: 'Meeting minute Deleted successfully',
        FOLDER_CREATED: 'Folder created successfully',
        FOLDER_UPDATED: 'Folder updated successfully',
        FOLDER_DELETED: 'Folder deleted successfully',
        PASSWORD_RESETTED: 'Password resetted successfully',
        REMINDER_DELETED: 'Reminder deleted successfully',
        REMINDER_CREATED: 'Reminder Created Successfully',
        EMPLOYEE_ADDED: 'Employee added successfully',
        EMPLOYEE_UPDATED: 'Employee updated successfully',
        EXCEL_UPLOADED: 'Successfully uploaded Excel',
        EMPLOYMENT_STATUS: 'Successfully updated status',
        TASK_FORWARDED: 'Successfully forwarded Task'
      },
      ERROR_ALERT: {
        BAD_HAPPENED: 'Oops, Something bad happened',
        MIC_NOT_FOUND: 'Mic not found',
        IMAGE_UPLOAD_FAILED: 'Image upload failed',
        FILE_UPLOAD_FAILED: 'Error while uploading file'
      },
      WARNING: {
        FILE_TYPE_EXE: "File type(.exe) is not allowed!",
        FILE_SIZE_LIMIT: "File size limit(25 Mb) exceeds!"
      },
      NO_DATA: {
        NO_FILES_FOUND: "No files found",
        NO_ISSUES_FOUND: "No issues found" 
      }
    },
    NO_DATA: {
      NO_TASK: 'No task found',
      NO_BUSINESS_LEADS: 'No business leads found'
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      }
    }
  }
};
